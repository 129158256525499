// dependencies
import { useState } from "react";

// libraries
import { noop } from "@gdf/shared/src/libraries";
import createHoverMonitor from "../libraries/utils/createHoverMonitor";

const hover = createHoverMonitor();

/**
 * Hook qui permet de retouner un état `hovered` à `true` si
 *   l'élément est survolé, `false` sinon.
 */
const useHover = ({ onHoverIn = noop, onHoverOut = noop } = {}) => {
  const [hovered, setHovered] = useState(false);

  const handleMouseEnter = () => {
    if (hover.enabled && !hovered) {
      onHoverIn();
      setHovered(true);
    }
  };

  const handleMouseLeave = () => {
    if (hovered) {
      onHoverOut();
      setHovered(false);
    }
  };

  return {
    onMouseEnter: handleMouseEnter,
    onMouseLeave: handleMouseLeave,
    hovered,
  };
};

export default useHover;

// dependencies
import React, { useCallback, useState } from "react";
import { View, StyleSheet, TouchableWithoutFeedback } from "react-native";
import { useIntl } from "react-intl";

// components
import BaseComponent from "@gdf/resources/src/components/BaseComponent";
import Stack from "@gdf/resources/src/components/Stack";
import Text from "@gdf/resources/src/components/Text";
import Button from "@gdf/resources/src/components/Button";
import UIContainer from "@gdf/resources/src/components/UIContainer";
import Media from "@gdf/resources/src/components/Media";
import { Row, Col } from "@gdf/resources/src/components/Grid";
import { List, ListItem } from "@gdf/resources/src/components/List";
import { IconCaretDown, IconCaretUp } from "@gdf/svg-icon-library";

// libraries
import { emToPx } from "@gdf/resources/src/libraries";

type PropsType = {
  onPressAccept: () => void;
  onPressRefuse: () => void;
};

const styles = StyleSheet.create({
  cookieConsent: {
    backgroundColor: "#f8f3ea",
  },
  container: {
    padding: emToPx(1),
  },
  detail: {
    flexDirection: "row",
    justifyContent: "flex-end",
  },
  detailContainer: {
    flexDirection: "row",
    alignItems: "center",
  },
  detailText: {
    fontSize: emToPx(0.5),
  },
});

const CookieConsent: React.FunctionComponent<PropsType> = (props) => {
  const { onPressAccept, onPressRefuse } = props;

  const intl = useIntl();

  const [detailOpened, setDetailOpened] = useState(false);

  const handleClickDetail = useCallback(() => {
    setDetailOpened(!detailOpened);
  }, [detailOpened]);

  return (
    <BaseComponent containerStyle={styles.cookieConsent}>
      <UIContainer isFullWidth={true}>
        <View style={styles.container}>
          <Row
            breakpointList={{ sm: 700, md: Infinity }}
            col={{ sm: 1, md: 3 }}
            gutter={0.5}
          >
            <Col span={{ sm: 1, md: 2 }}>
              <List gutter={0.5}>
                <ListItem>
                  <Text style={{ fontSize: emToPx(1.25) }}>
                    {intl.formatMessage({
                      id: "app.components.cookieConsent.title",
                    })}
                  </Text>
                </ListItem>

                <ListItem>
                  <Media query={{ minWidth: 700 }}>
                    {(matches) => (
                      <>
                        {!matches && (
                          <>
                            <View style={styles.detail}>
                              <TouchableWithoutFeedback
                                onPress={handleClickDetail}
                              >
                                <View style={styles.detailContainer}>
                                  <Text>{`${intl.formatMessage({
                                    id: "app.components.cookieConsent.detail",
                                  })} `}</Text>
                                  <Text style={styles.detailText}>
                                    {detailOpened ? (
                                      <IconCaretUp />
                                    ) : (
                                      <IconCaretDown />
                                    )}
                                  </Text>
                                </View>
                              </TouchableWithoutFeedback>
                            </View>

                            <Stack marginTop={0.5} />
                          </>
                        )}

                        {(matches || detailOpened) && (
                          <Text style={{ fontSize: emToPx(0.75) }}>
                            {intl.formatMessage({
                              id: "app.components.cookieConsent.description",
                            })}
                          </Text>
                        )}
                      </>
                    )}
                  </Media>
                </ListItem>
              </List>
            </Col>

            <Col>
              <List gutter={1}>
                <ListItem>
                  <Button
                    accessibilityLabel={intl.formatMessage({
                      id: "app.components.cookieConsent.refuse",
                    })}
                    onPress={onPressRefuse}
                  >
                    {intl.formatMessage({
                      id: "app.components.cookieConsent.refuse",
                    })}
                  </Button>
                </ListItem>

                <ListItem>
                  <Button
                    color="reversed-brand"
                    accessibilityLabel={intl.formatMessage({
                      id: "app.components.cookieConsent.accept",
                    })}
                    onPress={onPressAccept}
                  >
                    {intl.formatMessage({
                      id: "app.components.cookieConsent.accept",
                    })}
                  </Button>
                </ListItem>
              </List>
            </Col>
          </Row>
        </View>
      </UIContainer>
    </BaseComponent>
  );
};

export default CookieConsent;

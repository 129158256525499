// polyfill
import "core-js/features/set";
import "core-js/features/map";
import "core-js/features/array/find";
import "core-js/features/array/find-index";
import "core-js/features/array/includes";
import "core-js/features/array/some";
import "core-js/features/array/every";
import "core-js/features/array/fill";
import "core-js/features/object/values";
import "core-js/features/string/starts-with";
import "core-js/features/string/ends-with";
import "core-js/features/string/pad-start";
import "core-js/features/promise";
import "core-js/features/weak-map";
import "@formatjs/intl-pluralrules/polyfill";
import "@formatjs/intl-pluralrules/locale-data/en";
import "@formatjs/intl-pluralrules/locale-data/fr";
import "@formatjs/intl-getcanonicallocales/polyfill";

// dependencies
import React from "react";
import App from "next/app";
import Head from "next/head";
import { IntlProvider } from "react-intl";
import { register, unregister } from "next-offline/runtime";
import { StyleSheet } from "aphrodite";

// api
import { getCookies } from "@gdf/resources/src/api/cookies";
import { getDomainOrChannelId } from "@gdf/resources/src/api/domain";
import {
  getConfiguration,
  setConfigurationToWindow,
} from "@gdf/resources/src/api/configuration";
import {
  getMessages,
  setMessagesToStorage,
} from "@gdf/resources/src/api/messages";
import { getTheme, setThemeToStorage } from "@gdf/resources/src/api/theme";
import { getRoute } from "@gdf/resources/src/api/route";
import { getUserToken } from "@gdf/resources/src/api/user";

// constants
import theming from "@gdf/resources/src/constants/theming";
import browser from "@gdf/resources/src/constants/browser";
import { GOOGLE_TRACKING_COOKIE_NAME } from "@gdf/resources/src/constants/googleTracking";
import { apiRouter, frontRouter } from "@gdf/shared/src/constants/router";

// contexts
import { ConfigurationProvider } from "@gdf/resources/src/contexts/configuration";
import { RouteProvider } from "@gdf/resources/src/contexts/route";
import { CookiesProvider } from "@gdf/resources/src/contexts/cookies";
import { UserTokenProvider } from "@gdf/resources/src/contexts/userToken";
import { RequestContextProvider } from "@gdf/resources/src/contexts/requestContext";
import { RoutersProvider } from "@gdf/resources/src/contexts/routers";
import { GoogleTrackingProvider } from "@gdf/resources/src/contexts/GoogleTracking";

// components
import GlobalError from "@gdf/resources/src/components/GlobalError";

// containers
import Layout from "../containers/Layout";
import ErrorBoundary from "@gdf/resources/src/containers/ErrorBoundary";
import RoutingProgressBar from "@gdf/resources/src/containers/RoutingProgressBar";

// helpers
import { appGetRouters } from "@gdf/resources/src/helpers/app";
import { nextGetUri, nextGetAppUri } from "@gdf/resources/src/helpers/next";

// utils
import { generateUri, sanitizeHostname } from "@gdf/shared/src/libraries";
import {
  getGoogleTrackingServiceUsingConfiguration,
  getGoogleTrackingKeyUsingConfiguration,
  getTrackingState,
} from "@gdf/resources/src/libraries/utils/googleTracking";
import { buildFetcher } from "@gdf/resources/src/libraries/utils/fetcher";

// errors
import { BaseError } from "@gdf/resources/src/errors";

// pkg
import pkg from "../package.json";

import "@gdf/shared/src/css/index.css";
import "./styles.css";

if (browser) {
  StyleSheet.rehydrate(window.__REHYDRATE_IDS);
}

const { ThemeProvider } = theming;

class MyApp extends App {
  static async getInitialProps(appContext) {
    const {
      Component,
      ctx,
      router: {
        locale,
        defaultLocale = process.env.NEXT_PUBLIC_DEFAULT_LOCALE,
      },
    } = appContext;

    let pageProps = {};

    let error = null;

    const cookies = getCookies({ req: ctx.req });

    const url = new URL(nextGetUri({ ctx }));

    const uri = nextGetAppUri({
      url,
      locale,
    });

    const domainOrChannelId = getDomainOrChannelId({
      cookies,
      uri,
      res: ctx.res,
      hostname: sanitizeHostname({
        hostname: process.browser
          ? window.location.hostname
          : ctx.req.headers.host,
        subdomains: [process.env.NEXT_PUBLIC_FRONT_SUBDOMAIN],
      }),
    });

    try {
      // Récupère la configuration du channel.
      const { configuration } = await getConfiguration({
        domainOrChannelId,
      });

      // Récupère les messages de traduction
      const { messages } = await getMessages({ locale, defaultLocale });

      const realm = configuration.channel.realm.reference.toLowerCase();

      /* Récupère le thème */
      const { theme } = await getTheme({ realm, uri });

      /* Récupère la route courante */
      const { route } = await getRoute({
        router: frontRouter,
        uri,
      });

      const routers = appGetRouters({
        domain: configuration.channel.website.domain,
        packageName: "front",
      });

      const { userToken } = await getUserToken({ cookies });

      if (Component.getInitialProps) {
        const requestContext = {
          channelId: configuration.channel.id,
          token: userToken.token,
          onUnauthorized: userToken.clearToken,
        };

        await Promise.resolve(
          Component.getInitialProps({
            ...ctx,
            configuration,
            route,
            language: locale,
            routers,
            query: uri.getParsedQuery(),
            cookies,
            isomorphicFetcher: buildFetcher(requestContext),
            requestContext,
          })
        )
          .then((initialPageProps) => {
            pageProps = initialPageProps;
          })
          .catch((err) => {
            if (err instanceof BaseError) {
              // Est-ce une erreur qu'on sait traiter

              error = err;

              if (!process.browser) {
                ctx.res.statusCode = err.code;
              }
            } else {
              // On ne sait pas traiter l'erreur, on laisse Next.js s'en occuper.
              return Promise.reject(err);
            }
          });
      }

      return {
        pageProps,
        language: locale,
        configuration,
        messages,
        theme,
        realm,
        route,
        cookies,
        domainOrChannelId,
        userToken,
        url,
        error,
      };
    } catch (error) {
      if ("development" === process.env.NODE_ENV) {
        throw error;
      } else {
        return {
          globalError: error,
        };
      }
    }
  }

  static getDerivedStateFromProps(props) {
    if (!props.globalError) {
      const uri = nextGetAppUri({
        url: new URL(props.url),
        locale: props.language,
      });

      let { route } = getRoute({
        router: frontRouter,
        uri,
      });

      if (null === route) {
        route = frontRouter.findByName("App.NotFound").toFilled();
      }

      const cookies = getCookies({ rawCookies: props.cookies });

      const { userToken } = getUserToken({ cookies });

      return {
        route,
        userToken,
      };
    }

    return {};
  }

  constructor(props) {
    super(props);

    if (!props.globalError) {
      setConfigurationToWindow({
        domainOrChannelId: props.domainOrChannelId,
        configuration: props.configuration,
      });

      setMessagesToStorage({
        language: props.language,
        messages: props.messages,
      });

      setThemeToStorage({ realm: props.realm, theme: props.theme });

      this.state = {
        route: null,
        requesterManager: null,
        userToken: null,
        routers: appGetRouters({
          domain: props.configuration.channel.website.domain,
          packageName: "front",
        }),
      };
    } else {
      this.state = {};
    }
  }

  componentDidMount() {
    register();
  }

  componentWillUnmount() {
    // Désactivation du service-worker.
    unregister();
  }

  render() {
    const {
      Component,
      pageProps,
      language,
      messages,
      configuration,
      theme,
      cookies,
      realm,
      error,
      globalError,
    } = this.props;
    if (globalError) {
      return <GlobalError error={globalError} />;
    }

    const { route, userToken, routers } = this.state;

    const { siteVerificationKey } =
      configuration.channel.thirdPartyServices.google;

    const twitterObjectConfiguration = configuration.channel.socials.tw;
    const twitterAccount = twitterObjectConfiguration
      ? twitterObjectConfiguration.url.match(/^https:\/\/twitter\.com\/(.*)$/)
      : undefined;

    const uri = generateUri({
      route,
      scheme: "https",
      host: configuration.channel.website.domain,
    });

    return (
      <>
        <Head>
          <meta
            name="viewport"
            content="width=device-width, initial-scale=1.0"
          />
          <meta httpEquiv="X-UA-Compatible" content="ie=edge" />
          <meta name="author" content={configuration.channel.title} />
          <meta property="og:site_name" content={configuration.channel.title} />
          <meta property="og:type" content="website" />
          <meta name="twitter:card" content="summary" />
          <meta name="twitter:url" content={uri} />
          <meta property="og:url" content={uri} />
          {twitterAccount && (
            <meta name="twitter:creator" content={twitterAccount[1]} />
          )}

          {null !== route &&
            route.getAlternates().map((alternateRoute) => (
              <link
                key={alternateRoute.getParameter("lang")}
                rel="alternate"
                hrefLang={alternateRoute.getParameter("lang")}
                href={generateUri({
                  route: alternateRoute,
                  scheme: "https",
                  host: `${process.env.NEXT_PUBLIC_FRONT_SUBDOMAIN}.${configuration.channel.website.domain}`,
                })}
              />
            ))}

          {null != theme.FONT_FAMILY_URL && (
            <link
              href={theme.FONT_FAMILY_URL}
              rel="stylesheet"
              type="text/css"
            />
          )}

          <link
            rel="preconnect dns-prefetch"
            href={generateUri({
              router: apiRouter,
              name: "Api.Info",
            })}
          />

          {/* Met en cache l'image de fallback */}
          <link rel="prefetch dns-prefetch" href="/images/fallback.png" />

          <meta name="apple-mobile-web-app-capable" content="yes" />
          <meta name="mobile-web-app-capable" content="yes" />
          <meta name="apple-mobile-web-app-status-bar-style" content="black" />
          <meta name="format-detection" content="telephone=no" />
          <link
            rel="apple-touch-icon"
            sizes="144x144"
            href={`/favicons/${realm}/apple-touch-icon.png?v=${pkg.version}`}
          />
          <link
            rel="icon"
            type="image/png"
            href={`/favicons/${realm}/favicon-32x32.png?v=${pkg.version}`}
            sizes="32x32"
          />
          <link
            rel="icon"
            type="image/png"
            href={`/favicons/${realm}/favicon-16x16.png?v=${pkg.version}`}
            sizes="16x16"
          />
          <link
            rel="icon"
            type="image/png"
            href={`/favicons/${realm}/android-chrome-192x192.png?v=${pkg.version}`}
            sizes="192x192"
          />
          <link
            rel="icon"
            type="image/png"
            href={`/favicons/${realm}/android-chrome-512x512.png?v=${pkg.version}`}
            sizes="512x512"
          />
          <link
            rel="mask-icon"
            href={`/favicons/${realm}/safari-pinned-tab.svg?v=${pkg.version}`}
            color={theme.PRIMARY_COLOR}
          />
          <link rel="manifest" href={`/favicons/${realm}/manifest.json`} />
          <meta name="msapplication-TileColor" content="#ffffff" />
          <meta
            name="msapplication-TileImage"
            content={`/favicons/${realm}/mstile-150x150.png?v=${pkg.version}`}
          />
          <meta name="theme-color" content="#ffffff" />
          <link rel="shortcut icon" href={`/favicons/${realm}/favicon.ico`} />
          <meta
            name="msapplication-config"
            content="/favicons/gdf/browserconfig.xml"
          />

          <title key="title">{configuration.channel.title}</title>

          {siteVerificationKey && (
            <meta
              name="google-site-verification"
              content={siteVerificationKey}
            />
          )}
        </Head>

        <RoutingProgressBar />

        <IntlProvider locale={language} messages={messages}>
          <ThemeProvider theme={theme}>
            <ConfigurationProvider configuration={configuration}>
              <RouteProvider route={route}>
                <CookiesProvider initialCookies={cookies}>
                  <UserTokenProvider userToken={userToken}>
                    <RequestContextProvider
                      requestContext={{
                        channelId: configuration.channel.id,
                        userToken,
                      }}
                    >
                      <RoutersProvider routers={routers}>
                        <GoogleTrackingProvider
                          trackingService={getGoogleTrackingServiceUsingConfiguration(
                            { configuration }
                          )}
                          trackingKey={getGoogleTrackingKeyUsingConfiguration({
                            configuration,
                          })}
                          initialState={getTrackingState({
                            cookieValue: cookies[GOOGLE_TRACKING_COOKIE_NAME],
                          })}
                        >
                          <Layout>
                            <ErrorBoundary error={error}>
                              <Component {...pageProps} />
                            </ErrorBoundary>
                          </Layout>
                        </GoogleTrackingProvider>
                      </RoutersProvider>
                    </RequestContextProvider>
                  </UserTokenProvider>
                </CookiesProvider>
              </RouteProvider>
            </ConfigurationProvider>
          </ThemeProvider>
        </IntlProvider>
      </>
    );
  }
}

export default MyApp;

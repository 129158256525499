// dependencies
import React, { useMemo } from "react";
import { StyleSheet, css } from "aphrodite";

// constants
import theming from "@gdf/resources/src/constants/theming";

const { useTheme } = theming;

type PropsType = {
  aStyle?;
  style?: React.CSSProperties;
  as?;
} & React.HTMLProps<HTMLDivElement>;

const styles = StyleSheet.create({
  baseComponent: {
    display: "flex",
    flexDirection: "row",
  },
});

const BaseComponent: React.FunctionComponent<PropsType> = (props) => {
  const { aStyle, style, children, as, ...otherProps } = props;

  const theme = useTheme();

  const dynamicStyles = useMemo(
    () =>
      StyleSheet.create({
        baseComponent: {
          fontFamily: theme.FONT_FAMILY,
          fontSize: theme.FONT_SIZE,
          color: theme.PRIMARY_COLOR_TEXT,
        },
      }),
    [theme]
  );

  const Tag = as;

  return (
    <Tag
      {...otherProps}
      className={css(styles.baseComponent, dynamicStyles.baseComponent, aStyle)}
      style={style}
    >
      {children}
    </Tag>
  );
};

BaseComponent.defaultProps = {
  as: "div",
};

export default BaseComponent;

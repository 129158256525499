// constants
import {
  USER_TOKEN_COOKIE_NAME,
  USER_LOGIN_CONTEXT_WEBSITE,
  USER_RENEW_DELAY,
} from "@gdf/resources/src/constants/user";
import { apiRouter } from "@gdf/shared/src/constants/router";
import { MILLISECONDS_TO_SECOND_BASE } from "@gdf/shared/src/constants/time";

// libraries
import { EventDispatcher, Event } from "@gdf/shared/src/libraries/Event";
import JWTDecode from "@gdf/shared/src/libraries/JWTDecode";
import { generateUri, get, request } from "@gdf/shared/src/libraries";

// helpers
import { userIsTokenDataValid } from "@gdf/resources/src/helpers/user";

// api
import { deleteCookie, setCookie } from "@gdf/resources/src/api/cookies";

/**
 * On stocke dans une classe de manière à utiliser la mutation controllée plutôt
 *   que l'immutabilité.
 * Cela a pour conséquence d'améliorer les performances et d'empêcher de mettre à jour des parties
 *   du VDOM inutilement.
 */
class UserToken extends EventDispatcher {
  /**
   * @var {Date|undefined} expires
   * @param {object} param0
   * @param {object} param0.user
   * @param {boolean} param0.forceValidityToSession Si on ne souhaite ne
   *   pas tenir compte de la date d'expiration et
   *   forcer la destruction du cookie lorsque le navigateur se ferme.
   */
  public static getExpires({ user, forceValidityToSession = false }) {
    return user && !forceValidityToSession
      ? new Date(Number(user.exp) * MILLISECONDS_TO_SECOND_BASE)
      : undefined;
  }

  public token: string;

  public user;

  constructor(options: { token: string } = {} as { token: string }) {
    super();

    const { token = null } = options;

    this.setToken({ token });

    this.initSingleRenewal = this.initSingleRenewal.bind(this);
  }

  /**
   * Get host without subdomains
   */
  private static getMainHost({ host }: { host: string }): string {
    for (const subdomains of [
      process.env.NEXT_PUBLIC_FRONT_SUBDOMAIN,
      process.env.NEXT_PUBLIC_ACCOUNT_SUBDOMAIN,
      process.env.NEXT_PUBLIC_BOOKING_SUBDOMAIN,
    ]) {
      if (host.startsWith(subdomains)) {
        return host.slice(subdomains.length + 1);
      }
    }
    return host;
  }

  /**
   * - Récupérer le token
   * - Récupérer la date actuelle
   * - Faire la différence entre la date du token et celle actuelle
   * - Si la date est positive et supérieure à un temps donné (disons 10 minutes)
   *   - On crée un timer qui va automatiquement renouveler le token.
   * - Sinon si la est positive mais inférieur à un temps donné (toujours 10 minutes)
   *   - On appelle immédiatemment le renouvellement du token
   * - Sinon on ne fait rien
   */
  private async initSingleRenewal({ forceValidityToSession } = {} as any) {
    if (process.browser) {
      if (null !== this.token) {
        // Si on a un token

        const expires = UserToken.getExpires({
          user: this.user,
          forceValidityToSession,
        });

        if (undefined !== expires) {
          // Si l'expiration est valide.

          const diff = expires.getTime() - new Date().getTime();

          if (diff <= USER_RENEW_DELAY) {
            // Si le token est valide et que sa date de validité
            //   est inférieur au seuil fixé, on lance un renouvellement
            //   du token.

            await this.loginByToken();

            this.initSingleRenewal({ forceValidityToSession });

            return;
          } else if (diff > USER_RENEW_DELAY) {
            // Si la date du validité du token est supérieure au seuil,
            //   on crée un timer qui lancera le renouvellement du token.

            setTimeout(
              () => this.initSingleRenewal(),
              diff - USER_RENEW_DELAY + 100
            );

            return;
          }
        }
      }

      this.clearToken();
    }
  }

  /**
   * Définit le cookie
   */
  setCookie(options: any = {}) {
    const { token, user, forceValidityToSession } = options;
    const expires =
      options.expires ??
      UserToken.getExpires({
        forceValidityToSession,
        user,
      });

    setCookie({
      name: USER_TOKEN_COOKIE_NAME,
      value: token,
      path: "/",
      expires,
      sameSite: "lax",
      secure: true,
      httpOnly: false,
      domain:
        "undefined" !== typeof window
          ? UserToken.getMainHost({ host: window.location.host })
          : undefined,
    });
  }

  /**
   * Stocke le token et parse la partie public afin d'en garder une
   *   trace.
   */
  private setToken({ token, forceValidityToSession = false }) {
    if (null !== token) {
      // S'il n'y a pas de token.

      try {
        // On tente de décoder le token

        const user = JWTDecode.getPayload(token);

        if (userIsTokenDataValid({ tokenData: user })) {
          this.user = user;
          this.token = token;

          this.setCookie({ token, forceValidityToSession });

          this.initSingleRenewal({ forceValidityToSession });

          return;
        }
      } catch (error) {
        console.error(error);
      }
    }

    if (process.browser) {
      deleteCookie({
        name: USER_TOKEN_COOKIE_NAME,
        sameSite: "lax",
      });
    }

    this.token = null;
    this.user = null;
  }

  /**
   * Reconnecte un utilisateur à l'aide d'un token qui aura été renouvelé.
   */
  public async loginByToken(options: any = {}) {
    if (options.token) {
      this.setToken({ token: options.token });
    } else {
      const userId = this.user.userId;

      return request({
        url: generateUri({
          router: apiRouter,
          name: "Api.Action.User.Action.Whois",
          parameters: {
            userId,
          },
        }),
        method: "POST",
        context: {
          token: this.token,
          channelId: null,
          onUnauthorized: null,
        },
      }).then((response) => {
        const token = get(response, "meta.token", null);

        this.setToken({ token });

        this.dispatch(new Event("login"));
        this.dispatch(new Event("update"));
      });
    }
  }

  /**
   * Connecte un utilisateur à l'aide
   *   d'un nom d'utilisateur et d'un mot de passe.
   */
  public async loginByCredentials({ username, password, remember }) {
    const route = apiRouter.findByName("Api.Action.User.Login").toFilled();

    await request({
      url: route.generateUri().toString(),
      method: route.getMethods()[0],
      type: "formData",
      body: {
        username,
        password,
        remember,
        context: USER_LOGIN_CONTEXT_WEBSITE,
      },
    }).then((response) => {
      const token = get(response, "body.data.token", null);

      this.setToken({ token });

      this.dispatch(new Event("login"));
      this.dispatch(new Event("update"));
    });
  }

  /**
   * Déconnecte un utilisateur.
   */
  public logout() {
    this.clearToken();
  }

  /**
   * On supprime les données de connexion de l'utilisateur
   *   (cookies et informations passées dans le Provider).
   */
  public clearToken() {
    this.setToken({ token: null });

    this.dispatch(new Event("logout"));
    this.dispatch(new Event("update"));
  }

  toJSON() {
    return {
      token: this.token,
    };
  }
}

export default UserToken;

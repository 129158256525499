// dependencies
import React from "react";
import { StyleSheet } from "react-native";

// components
import { LinkMenu } from "@gdf/resources/src/components/Link";
import UIContainer from "@gdf/resources/src/components/UIContainer";
import BaseComponent from "@gdf/resources/src/components/BaseComponent";

// hocs
import withMedia, {
  WithMediaInjectedPropsType,
} from "@gdf/resources/src/hocs/withMedia";

// libraries
import { emToPx } from "@gdf/resources/src/libraries";

export type ILink = {
  label: string;
  to: string;
  isActivated: boolean;
  type?: "basic" | "highlight" | string;
};

type PropsType = {
  linkList?: ILink[];
} & WithMediaInjectedPropsType;

const styles = StyleSheet.create({
  view: {
    borderStyle: "solid",
    borderBottomWidth: emToPx(0.0625),
    borderBottomColor: "#f8f3ea",
  },
  uicontainer: {
    flexDirection: "row",
  },
  link: {
    display: "flex",
    paddingTop: emToPx(0.875),
    paddingBottom: emToPx(0.875),
    paddingRight: emToPx(0.3125),
    paddingLeft: emToPx(0.3125),
    borderStyle: "solid",
    borderRightWidth: emToPx(0.0625),
    borderRightColor: "#f8f3ea",
    flexGrow: 1,
    alignItems: "center",
    textAlign: "center",
    justifyContent: "center",
  },
  link__lastChild: {
    borderRightWidth: 0,
  },
});

const MainNavigation: React.FunctionComponent<PropsType> = (props) => {
  const { linkList, matches } = props;

  return (
    <>
      {matches && (
        <BaseComponent childrenStyle={styles.view}>
          <UIContainer style={styles.uicontainer} isFullWidth={true}>
            {linkList.map((link, index) => {
              const { label, type, to, isActivated } = link;

              return (
                <LinkMenu
                  style={[
                    styles.link,
                    index + 1 === linkList.length && styles.link__lastChild,
                  ]}
                  key={index}
                  isActivated={isActivated}
                  type={type}
                  to={to}
                >
                  {label}
                </LinkMenu>
              );
            })}
          </UIContainer>
        </BaseComponent>
      )}
    </>
  );
};

MainNavigation.defaultProps = {
  linkList: [],
};

export default withMedia({ query: { minWidth: 1064 } })(MainNavigation);

// dependencies
import React from "react";
import { View, StyleSheet } from "react-native";

// libraries
import { emToPx } from "../../libraries";

export type IProps = {
  paddingTop?: number;
  paddingRight?: number;
  paddingBottom?: number;
  paddingLeft?: number;
  marginTop?: number;
  marginRight?: number;
  marginBottom?: number;
  marginLeft?: number;
};

const Stack: React.FunctionComponent<IProps> = ({
  children,
  paddingTop,
  paddingRight,
  paddingBottom,
  paddingLeft,
  marginTop,
  marginRight,
  marginBottom,
  marginLeft,
}): JSX.Element => {
  const styles = StyleSheet.create({
    stack: {
      paddingTop: emToPx(paddingTop),
      paddingRight: emToPx(paddingRight),
      paddingBottom: emToPx(paddingBottom),
      paddingLeft: emToPx(paddingLeft),
      marginTop: emToPx(marginTop),
      marginRight: emToPx(marginRight),
      marginBottom: emToPx(marginBottom),
      marginLeft: emToPx(marginLeft),
    },
  });
  return <View style={styles.stack}>{children}</View>;
};

Stack.defaultProps = {
  paddingTop: 0,
  paddingRight: 0,
  paddingBottom: 0,
  paddingLeft: 0,
  marginTop: 0,
  marginRight: 0,
  marginBottom: 0,
  marginLeft: 0,
};

export default Stack;

// dependencies
import React from "react";
import {
  ActivityIndicator as ActivityIndicatorReactNative,
  ViewStyle,
  StyleProp,
} from "react-native";

// theming
import theming, { IPropsWithTheme } from "../../constants/theming";

const { withTheme } = theming;

type IProps = {
  style?: StyleProp<ViewStyle>;
  color?: string;
  hidesWhenStopped?: boolean;
  size?: "small" | "large";
} & IPropsWithTheme;

const ActivityIndicator: React.FunctionComponent<IProps> = ({
  theme,
  style,
  size,
  hidesWhenStopped,
  color,
}): JSX.Element => {
  const localColor = color ? color : theme.SECONDARY_COLOR;

  return (
    <ActivityIndicatorReactNative
      hidesWhenStopped={hidesWhenStopped}
      size={size}
      style={style}
      color={localColor}
    />
  );
};

ActivityIndicator.defaultProps = {
  size: "large",
  hidesWhenStopped: false,
};

export default withTheme(ActivityIndicator);

// dependencies
import React, { useContext, useState, useEffect, useMemo } from "react";

const requestContext = React.createContext<{
  userToken: ReturnType<
    typeof import("../api/user/getUserToken").default
  >["userToken"];
  channelId: string;
  onUnauthorized: () => void;
}>(null);

type ProviderPropsType = {
  requestContext: {
    userToken: ReturnType<
      typeof import("../api/user/getUserToken").default
    >["userToken"];
    channelId: string;
  };
};

export const RequestContextProvider: React.FunctionComponent<
  ProviderPropsType
> = (props) => {
  const { requestContext: value, ...otherProps } = props;

  const { userToken, channelId } = value;

  const Provider = requestContext.Provider;

  return <Provider {...otherProps} value={{ userToken, channelId }} />;
};

export const RequestContextConsumer = requestContext.Consumer;

export const useRequestContext = () => {
  const context = useContext(requestContext);
  const [, forceUpdate] = useState(null);

  useEffect(() => {
    context.userToken.on("update", forceUpdate);

    return () => {
      context.userToken.off("update", forceUpdate);
    };
  }, [context.userToken]);

  const token = context.userToken.token;
  const channelId = context.channelId;
  const onUnauthorized = context.userToken.clearToken;

  return useMemo(
    () => ({
      token,
      channelId,
      onUnauthorized,
    }),
    [token, channelId, onUnauthorized]
  );
};

export default requestContext;

// libraries
import { Router, FilledRoute } from "@gdf/shared/src/libraries/Router";
import Uri from "@gdf/shared/src/libraries/Uri";

type GetRouteByNameAndParametersType = {
  router: Router;
  name: string;
  parameters: { [parameterName: string]: string };
  uri: Uri;
};

function getRouteByNameAndParameters({
  router,
  name,
  parameters,
  uri,
}: GetRouteByNameAndParametersType): FilledRoute {
  const route = router.findByName(name).toFilled();

  for (const parameterName of Object.keys(parameters)) {
    const parameterValue = parameters[parameterName];

    route.setParameter(parameterName, parameterValue);
  }

  route.setUri(uri);

  return route;
}

type GetRouteType = { router: Router } & Partial<
  {
    req: import("http").IncomingMessage;
    uri: Uri;
  } & {
    rawRoute: { name: string; parameters: { [parameterName: string]: string } };
  }
>;

/**
 * Récupère la route courante.
 */
const getRoute = ({
  router,
  uri,
  rawRoute,
}: GetRouteType): { route: FilledRoute } => {
  if (undefined !== rawRoute) {
    // Si a déjà été pré-calculée

    if (rawRoute instanceof FilledRoute) {
      // La route est de type `FilledRoute` (donc on se trouve côté serveur)

      return { route: rawRoute };
    } else {
      // La route est de type `FilledRoute` mais sérialisé (donc un objet)
      //   On recrée alors cette route en tant qu'instance de `FilledRoute`
      //   en fonction des ses informations.

      return {
        route: getRouteByNameAndParameters({
          router,
          name: rawRoute.name,
          parameters: rawRoute.parameters,
          uri,
        }),
      };
    }
  }

  const route = router.match(uri);

  return { route };
};

export default getRoute;

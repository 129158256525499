/**
 * Nettoie le hostname pour ne garder que le domaine brut.
 * @param param0
 * @param {string} param0.hostname
 * @param {Array} param0.submain
 */
const sanitizeHostname = ({ hostname, subdomains }) => {
  const subDomainIndex = subdomains.findIndex((subdomain) =>
    hostname.startsWith(`${subdomain}.`)
  );

  if (subDomainIndex > -1) {
    return hostname.slice(subdomains[subDomainIndex].length + 1);
  }

  return hostname;
};

export default sanitizeHostname;

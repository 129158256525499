// dependencies
import cookie from "cookie";

/**
 * Défini la valeur d'un cookie.
 * @param {object} param0
 * @param {string} param0.name
 * @param {string} param0.value
 * @param {Date} [param0.expires]
 * @param {boolean} [param0.secure=false]
 * @param {number} [param0.maxAge]
 * @param {string} [param0.domain]
 * @param {boolean} [param0.httpOnly=false]
 * @param {string} [param0.path]
 * @param {"lax" | "none" | "strict"} [param0.sameSite]
 * @param {import("http").ServerResponse} [param0.res]
 */
function setCookie({
  name,
  value,
  expires,
  maxAge,
  domain,
  secure = false,
  httpOnly = false,
  sameSite = "lax",
  path = "/",
  res,
}) {
  const serializedCookie = cookie.serialize(name, value, {
    expires,
    secure,
    maxAge,
    httpOnly,
    domain,
    sameSite,
    path,
  });

  if (process.browser) {
    document.cookie = serializedCookie;
  } else if (res) {
    res.setHeader("Set-Cookie", serializedCookie);
  }
}

export default setCookie;

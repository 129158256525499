// libraries
import { get, generateUri } from "@gdf/shared/src/libraries";

// helpers
import { contentGenerateUriRelayOnType } from "@gdf/resources/src/helpers/content";

type LayoutGetMainNavigationLinkListParam0Type = {
  intl: import("react-intl").IntlShape;
  configuration: ReturnType<
    typeof import("@gdf/resources/src/normalizers/configuration").configurationParseData
  >;
  frontRouter: import("@gdf/shared/src/libraries/Router").Router;
  currentPath: string;
};

const layoutGetMainNavigationLinkList = ({
  intl,
  frontRouter,
  configuration,
  currentPath,
}: LayoutGetMainNavigationLinkListParam0Type) => {
  return [
    ...(get(configuration, "channel.website.contents.bySection.promo", [])
      .length > 0
      ? [
          {
            label: intl.formatMessage({ id: "front.containers.layout.deal" }),
            to: generateUri({
              router: frontRouter,
              name: "App.Action.DealList",
              parameters: {
                lang: intl.locale,
              },
            }),
            type: "highlight",
            isActivated:
              generateUri({
                name: "App.Action.DealList",
                router: frontRouter,
                parameters: {
                  lang: intl.locale,
                },
              }) === currentPath,
          },
        ]
      : []),
    ...get(configuration, "channel.website.contents.bySection.menu", []).map(
      (content) => ({
        label: content.title,
        to: contentGenerateUriRelayOnType({ intl, content, frontRouter }),
        isActivated:
          contentGenerateUriRelayOnType({ intl, content, frontRouter }) ===
          currentPath,

        type: "basic",
      })
    ),
  ];
};

export default layoutGetMainNavigationLinkList;

// dependencies
import React, { useContext } from "react";

import { Router } from "@gdf/shared/src/libraries/Router";

const routers = React.createContext<{
  accountRouter: Router;
  bookingRouter: Router;
  frontRouter: Router;
  mainRouter: Router;
}>(null);

type ProviderPropsType = {
  routers: {
    accountRouter: Router;
    bookingRouter: Router;
    frontRouter: Router;
    mainRouter: Router;
  };
};

const Provider = routers.Provider;

export const RoutersProvider: React.FunctionComponent<ProviderPropsType> = (
  props
) => {
  const { routers: value, ...otherProps } = props;

  return <Provider {...otherProps} value={value} />;
};

export const RoutersConsumer = routers.Consumer;

export const useRouters = () => useContext(routers);

export default routers;

// dependencies
import { promisify } from "util";

import AbstractCacheManager from "../AbstractCacheManager";

// libraries
import { promiseTimeout } from "@gdf/shared/src/libraries";

/**
 * Fonction d'encodage des données pour stockage dans redis
 * @param {*} value
 * @return {*} value encodé
 */
const encode = (value) => {
  if ("object" === typeof value) {
    value = JSON.stringify(value);
  }
  return value;
};

/**
 * Fonction de décodage des données récupérées depuis redis
 * @param {*} value
 * @return {*} value decodé
 */
const decode = (value) => {
  try {
    value = JSON.parse(value);
  } catch (err) {
    console.warn(err);
  }
  return value;
};

/**
 * Cache manager via le driver Redis
 */
class RedisCacheManager extends AbstractCacheManager {
  private redis;

  private client: import("redis").RedisClient;

  /**
   * Création d'un cache manager via un driver Redis
   * @param {import("redis").RedisClient} redis client redis
   * @see https://github.com/NodeRedis/node_redis
   */
  public constructor(redis: import("redis").RedisClient) {
    super();

    this.redis = {
      get: promisify(redis.get).bind(redis),
      set: promisify(redis.set).bind(redis),
      del: promisify(redis.del).bind(redis),
      exists: promisify(redis.exists).bind(redis),
    };

    this.client = redis;

    this.get = promiseTimeout(this.get.bind(this), 1000);
    this.has = promiseTimeout(this.has.bind(this), 1000);
    this.set = promiseTimeout(this.set.bind(this), 1000);
    this.delete = promiseTimeout(this.delete.bind(this), 1000);
  }

  /**
   * @inheritdoc
   */
  public async has(key: string): Promise<boolean> {
    return await this.redis.exists(await AbstractCacheManager.getHash(key));
  }

  /**
   * @inheritdoc
   */
  public async get(key: string): Promise<any> {
    const value = await this.redis.get(await AbstractCacheManager.getHash(key));

    return null != value && "string" === typeof value && value.length
      ? decode(value)
      : value;
  }

  /**
   * @inheritdoc
   */
  public async set(key: string, value, expiresAt?): Promise<void> {
    if (expiresAt > 0) {
      await this.redis.set(
        await AbstractCacheManager.getHash(key),
        encode(value),
        "EX",
        expiresAt
      );
    } else {
      await this.redis.set(
        await AbstractCacheManager.getHash(key),
        encode(value)
      );
    }
  }

  /**
   * @inheritdoc
   */
  public async delete(key: string): Promise<void> {
    await this.redis.del(await AbstractCacheManager.getHash(key));
  }
}

export default RedisCacheManager;

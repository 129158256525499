// deps
import cookie from "cookie";

// constants
import { DOMAIN_COOKIE_NAME, DOMAIN_QUERY_NAME } from "../../constants/domain";
import browser from "../../constants/browser";

/**
 * Récupère le nom de domaine ou le channel-id à utiliser pour récupérer la configuration.
 * Dans l'ordre, la fonction va:
 * - si un query-param existe, alors
 *   - on crée un nouveau cookie
 *   - on retourne cette valeur
 * - sinon si un cookie existe, alors
 *   - on retourne cette valeur
 * - sinon si on est en mode de développement et qu'une variable existe, alors
 *   - on retourne cette valeur
 * - sinon
 *   - on retourne le nom du domaine courant
 * @param {object} param0
 * @param {object} param0.cookies
 * @param {import("@gdf/shared/src/libraries/Uri").default} param0.uri
 * @param {import("http").ServerResponse} param0.res
 * @param {string} param0.hostname
 */
export default function getDomainOrChannelId({ cookies, uri, res, hostname }) {
  const query = uri.getParsedQuery();

  if (query[DOMAIN_QUERY_NAME]) {
    const domainOrChannelId = query[DOMAIN_QUERY_NAME];

    const serializedCookie = cookie.serialize(
      DOMAIN_COOKIE_NAME,
      domainOrChannelId
    );

    if (browser) {
      window.document.cookie = serializedCookie;
    } else {
      res.setHeader("Set-Cookie", serializedCookie);
    }

    return domainOrChannelId;
  }

  if (cookies[DOMAIN_COOKIE_NAME]) {
    // Si un cookie existe

    return cookies[DOMAIN_COOKIE_NAME];
  }

  return hostname;
}

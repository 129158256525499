// dependencies
import React from "react";
import { useIntl } from "react-intl";
import { StyleSheet, css } from "aphrodite";

// components
import Text from "@gdf/resources/src/components/Text2";
import { Row, Col } from "@gdf/resources/src/components/Grid2";
import Heading from "@gdf/resources/src/components/Heading";
import { LinkFooter } from "@gdf/resources/src/components/Link";
import Stack from "@gdf/resources/src/components/Stack";
import UIContainer from "@gdf/resources/src/components/UIContainer2";
import BaseComponent from "@gdf/resources/src/components/BaseComponent2";

// constants
import theming from "@gdf/resources/src/constants/theming";

import useViewportMedia from "@gdf/resources/src/hocs/useViewportMedia";

const { useTheme } = theming;

type PropsType = {
  columnList: {
    title: string;
    linkList: {
      to: string;
      label: string;
    }[];
  }[];
  contactNumber: string;
  openingTime: string;
  logo: React.ReactNode;
};

const styles = StyleSheet.create({
  logoColumn: {
    borderStyle: "solid",
    borderTopWidth: 0,
    borderRightWidth: 0,
    borderBottomWidth: "0.0625rem",
    borderLeftWidth: 0,
  },
  logoColumn__noBorder: {
    borderBottomWidth: 0,
  },
  openingTime: {
    fontSize: "0.75rem",
    whiteSpace: "pre-line",
  },
  link: {
    fontSize: "0.625rem",
  },
  heading: {
    fontWeight: 500,
  },
});

const getNumberOfColumns = ({ matchesSm, matchesMd }) => {
  if (!matchesSm) {
    return 1;
  }

  if (!matchesMd) {
    return 2;
  }

  return 4;
};

const MainFooter: React.FunctionComponent<PropsType> = (props) => {
  const { columnList, contactNumber, openingTime, logo } = props;

  const { matches: matchesSm } = useViewportMedia({
    queries: [{ minWidth: 800 }],
  });
  const { matches: matchesMd } = useViewportMedia({
    queries: [{ minWidth: 1040 }],
  });

  const intl = useIntl();

  const theme = useTheme();

  return (
    <BaseComponent as="section">
      <UIContainer fullWidth={true}>
        <Row
          type="cell"
          maxColumns={getNumberOfColumns({ matchesSm, matchesMd })}
          gutter={0.5}
        >
          {columnList.map((column, index) => {
            return (
              <Col key={index}>
                {column.linkList.length > 0 && (
                  <>
                    <Heading textStyle={styles.heading} level={4}>
                      {column.title}
                    </Heading>

                    <Stack marginTop={0.625} />

                    {column.linkList.map((link, index) => {
                      return (
                        <div key={index}>
                          <Text>
                            <LinkFooter to={link.to} style={styles.link}>
                              {link.label}
                            </LinkFooter>
                          </Text>
                        </div>
                      );
                    })}
                  </>
                )}
              </Col>
            );
          })}

          <Col>
            <div
              className={css(
                styles.logoColumn,
                !openingTime && !contactNumber && styles.logoColumn__noBorder
              )}
              style={{ borderBottomColor: theme.SECONDARY_COLOR }}
            >
              {logo}

              <Stack marginTop={0.625} />
            </div>
            {(openingTime || contactNumber) && (
              <>
                <Stack marginTop={0.625} />

                <div>
                  <Text>
                    {intl.formatMessage({
                      id: "app.component.mainFooter.reservationService",
                    })}
                  </Text>
                  <Stack marginTop={0.3125} />
                  {contactNumber && (
                    <>
                      <Text
                        data-gtm="footer-booking-phone"
                        style={{ fontSize: "1.625em", textDecoration: "none" }}
                        as="a"
                        href={`tel:${contactNumber}`}
                      >
                        {contactNumber}
                      </Text>
                      <Stack marginTop={0.625} />
                    </>
                  )}
                  <Text aStyle={styles.openingTime}>{openingTime}</Text>
                </div>
              </>
            )}
          </Col>
        </Row>
      </UIContainer>
    </BaseComponent>
  );
};

export default MainFooter;

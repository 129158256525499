import React from "react";
import {
  View,
  TouchableOpacity,
  StyleSheet,
  GestureResponderEvent,
  ViewStyle,
  TextStyle,
  StyleProp,
} from "react-native";

// libraries
import { noop } from "@gdf/shared/src/libraries";

import theming, { IPropsWithTheme } from "../../constants/theming";

// components
import Link from "../Link";
import Text from "../Text";

const { withTheme } = theming;

export type IProps = {
  viewStyle?: StyleProp<ViewStyle>;
  textStyle?: StyleProp<TextStyle>;
  accessibilityLabel: string;
  target?: "_self" | "_blank" | "_parent" | "_top" | string;
  size?: "default" | "small" | "medium" | "large" | "thin";
  color?: "default" | "brand" | "reversed-brand" | "reset";
  to?: string;
  disabled?: boolean;
  onPress?: (event: GestureResponderEvent) => void;
  external?: boolean;
} & IPropsWithTheme;

type IState = {};

class Button extends React.Component<IProps, IState> {
  public static defaultProps: Partial<IProps>;

  public getStyles(): StyleSheet.NamedStyles<{
    view: ViewStyle;
    text: TextStyle;
    view__colorBrand: ViewStyle;
    view__colorReversedBrand: ViewStyle;
    view__colorReset: ViewStyle;
    view__colorDefault: ViewStyle;
    view__sizeThin: TextStyle;
    view__disabled: ViewStyle;
    text__colorBrand: TextStyle;
    text__colorReversedBrand: TextStyle;
    text__colorReset: TextStyle;
    text__sizeSmall: TextStyle;
    text__sizeMedium: TextStyle;
    text__sizeLarge: TextStyle;
    text__disabled: TextStyle;
  }> {
    const { theme } = this.props;

    return StyleSheet.create({
      view: {
        backgroundColor: "#ffffff",
        borderColor: "currentColor",
        borderStyle: "solid",
        margin: 0,
        paddingTop: ".625em",
        paddingRight: "1.25em",
        paddingBottom: ".625em",
        paddingLeft: "1.25em",
        position: "relative",
        borderRadius: 3,
        maxWidth: "100%",
      },
      view__colorBrand: {
        backgroundColor: theme.SECONDARY_COLOR,
      },
      view__colorReversedBrand: {
        backgroundColor: theme.PRIMARY_COLOR,
      },
      view__colorReset: {
        borderColor: "#d7100f",
        borderWidth: "0.0625em" as any,
      },
      view__colorDefault: {
        borderWidth: "0.0625em" as any,
        borderColor: "#cfdadd",
      },
      view__sizeThin: {
        paddingTop: "0.625em",
        paddingRight: "0.5em",
        paddingBottom: "0.625em",
        paddingLeft: "0.5em",
      },
      view__disabled: {
        backgroundColor: "#9b9b9b",
        cursor: "not-allowed",
      } as ViewStyle,
      text: {
        cursor: "pointer" as any,
        fontSize: `${theme.FONT_SIZE / 16}rem` as any,
        textAlign: "center",
        textDecorationLine: "none",
        textAlignVertical: "auto",
      } as TextStyle,
      text__disabled: {
        cursor: "not-allowed",
      },
      text__colorBrand: {
        color: "#ffffff",
      },
      text__colorReversedBrand: {
        color: "#ffffff",
      },
      text__colorReset: {
        color: "#d7100f",
      },
      text__sizeSmall: {
        paddingTop: ".4em",
        paddingRight: ".2em",
        paddingBottom: ".4em",
        paddingLeft: ".2em",
      },
      text__sizeMedium: {
        paddingTop: ".4em",
        paddingRight: ".75em",
        paddingBottom: ".4em",
        paddingLeft: ".75em",
      },
      text__sizeLarge: {
        fontSize: "1.1em" as any,
      },
    });
  }

  public render(): JSX.Element {
    const {
      to,
      target,
      viewStyle,
      textStyle,
      size,
      color,
      theme,
      external,
      children,
      disabled,
      onPress,
      ...otherProps
    } = this.props;

    const styles = this.getStyles();

    const localViewStyle = [
      styles.view,
      color === "brand" && styles.view__colorBrand,
      color === "reversed-brand" && styles.view__colorReversedBrand,
      color === "reset" && styles.view__colorReset,
      (color === "default" || color == null) && styles.view__colorDefault,
      disabled && styles.view__disabled,
      size === "thin" && styles.view__sizeThin,
      viewStyle,
    ];

    const localTextStyle = [
      styles.text,
      color === "brand" && styles.text__colorBrand,
      color === "reversed-brand" && styles.text__colorReversedBrand,
      color === "reset" && styles.text__colorReset,
      size === "small" && styles.text__sizeSmall,
      size === "medium" && styles.text__sizeMedium,
      size === "large" && styles.text__sizeLarge,
      disabled && styles.text__disabled,
      textStyle,
    ];

    return to ? (
      <View {...otherProps} style={localViewStyle}>
        <Link
          style={localTextStyle}
          to={to}
          target={target}
          external={external}
        >
          {children}
        </Link>
      </View>
    ) : (
      <TouchableOpacity
        {...otherProps}
        disabled={disabled}
        activeOpacity={0.8}
        style={localViewStyle}
        onPress={onPress}
      >
        <Text style={localTextStyle}>{children}</Text>
      </TouchableOpacity>
    );
  }
}

Button.defaultProps = {
  size: "default",
  color: "default",
  target: "_self",
  disabled: false,
  onPress: noop,
};

export default withTheme(Button);

import React from "react";
import {
  View,
  StyleSheet,
  ViewStyle,
  StyleProp,
  ViewProperties,
} from "react-native";

// component
import withMedia from "../../hocs/withMedia";

export type IProps = {
  isFullWidth?: boolean;
  style?: StyleProp<ViewStyle>;
  matches: boolean;
} & ViewProperties;

const getStyles = (): StyleSheet.NamedStyles<{
  view: ViewStyle;
  fullView: ViewStyle;
  smallScreen: ViewStyle;
}> => {
  return StyleSheet.create({
    view: {
      flexDirection: "column",
      marginTop: 0,
      marginRight: "auto",
      marginBottom: 0,
      marginLeft: "auto",
      width: "73.75rem",
    },
    fullView: {
      maxWidth: "120rem",
      width: "100%",
      paddingTop: 0,
      paddingRight: "5em",
      paddingBottom: 0,
      paddingLeft: "5em",
    },
    smallScreen: {
      width: "100%",
      padding: "0 .625em",
      paddingTop: 0,
      paddingRight: "0.625em",
      paddingBottom: 0,
      paddingLeft: "0.625em",
    },
  });
};

const UIContainer: React.FunctionComponent<IProps> = ({
  children,
  isFullWidth,
  style,
  matches,
  ...attrs
}): JSX.Element => {
  const styles = getStyles();

  return (
    <View
      {...attrs}
      style={[
        styles.view,
        style,
        isFullWidth && styles.fullView,
        matches && styles.smallScreen,
      ]}
    >
      {children}
    </View>
  );
};

UIContainer.defaultProps = {
  isFullWidth: false,
};

export default withMedia({ query: { maxWidth: 1220 } })(UIContainer);

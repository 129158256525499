// dependencies
import React from "react";
import { StyleSheet, css } from "aphrodite";

// contexts
import { useRowContext } from "./context";

const styles = StyleSheet.create({
  cell: {},
  column: {
    width: "100%",
  },
});

type PropsType = {
  /**
   * The number of row to cover.
   */
  colSpan?: number;
  allowOverflow?: boolean;
  aStyle?;
} & React.HTMLProps<HTMLDivElement>;

const Col: React.FunctionComponent<PropsType> = (props) => {
  const { colSpan, className, allowOverflow, style, aStyle, ...otherProps } =
    props;

  const { type, maxColumns, verticalGutter, horizontalGutter } =
    useRowContext();

  return (
    <div
      {...otherProps}
      className={css(styles[type], aStyle)}
      style={{
        ...("inline" === type && {
          paddingLeft: `${horizontalGutter}rem`,
          paddingRight: `${horizontalGutter}rem`,
          paddingTop: `${verticalGutter}rem`,
          paddingBottom: `${verticalGutter}rem`,
        }),
        ...("inline" === type &&
          (allowOverflow
            ? {
                flexShrink: 0,
                flexGrow: 0,
                flexBasis: "auto",
              }
            : {
                flexGrow: 1,
                flexShrink: 1,
                flexBasis: "0%",
              })),
        ...("column" === type && {
          paddingTop: `${verticalGutter}rem`,
          paddingBottom: `${verticalGutter}rem`,
        }),
        ...("cell" === type && {
          width: `${(colSpan * 100) / maxColumns}%`,
          paddingLeft: `${horizontalGutter}rem`,
          paddingRight: `${horizontalGutter}rem`,
          paddingTop: `${verticalGutter}rem`,
          paddingBottom: `${verticalGutter}rem`,
        }),
        ...style,
      }}
    >
      {props.children}
    </div>
  );
};

Col.defaultProps = {
  colSpan: 1,
  allowOverflow: true,
};

export default Col;

// dependencies
import React, { useMemo } from "react";
import { StyleSheet, css } from "aphrodite";

// components
import Text from "@gdf/resources/src/components/Text";
import { IconCrossO } from "@gdf/svg-icon-library";

// constants
import theming from "@gdf/resources/src/constants/theming";

// libraries
import { noop, capitalize } from "@gdf/shared/src/libraries";

const { useTheme } = theming;

type PropsType = {
  appearance?: "primary" | "classic";
  closePlacement?: "left" | "right";
  onPressClose?;
};

const styles = StyleSheet.create({
  header: {
    padding: "0.625rem",
    width: "100%",
    flexDirection: "row",
    justifyContent: "space-between",
    alignItems: "center",
  },
  icon: {
    cursor: "pointer",
  },
  icon__left: {
    marginRight: "0.625rem",
  },
  icon__right: {
    marginLeft: "0.625rem",
  },
  children: {
    flex: 1,
  },
});

const DrawerHeader: React.FunctionComponent<PropsType> = (props) => {
  const { appearance, closePlacement, children, onPressClose } = props;

  const theme = useTheme();

  const dynamicStyles = useMemo(() => {
    return StyleSheet.create({
      header__appearancePrimary: {
        backgroundColor: theme.PRIMARY_COLOR,
        borderColor: theme.PRIMARY_COLOR,
        borderStyle: "solid",
        borderTopWidth: 0,
        borderRightWidth: 0,
        borderBottomWidth: "0.125rem",
        borderLeftWidth: 0,
        color: "#ffffff",
      },
      header__appearanceClassic: {
        backgroundColor: "#ffffff",
        borderColor: "#cfdadd",
        borderStyle: "solid",
        borderTopWidth: 0,
        borderRightWidth: 0,
        borderBottomWidth: "0.125rem",
        borderLeftWidth: 0,
        color: "#000000",
      },
    });
  }, [theme]);

  return (
    <div
      className={css(
        styles.header,
        dynamicStyles[`header__appearance${capitalize(appearance)}`]
      )}
    >
      <Text
        style={{
          display: "flex",
          color: "inherit",
          flexDirection: "row",
          alignItems: "center",
        }}
      >
        {"left" === closePlacement && (
          <div
            className={css(styles.icon, styles.icon__left)}
            onClick={onPressClose}
          >
            <IconCrossO />
          </div>
        )}

        <div className={css(styles.children)}>{children}</div>

        {"right" === closePlacement && (
          <div
            className={css(styles.icon, styles.icon__right)}
            onClick={onPressClose}
          >
            <IconCrossO />
          </div>
        )}
      </Text>
    </div>
  );
};

DrawerHeader.defaultProps = {
  appearance: "classic",
  closePlacement: "right",
  onPressClose: noop,
};

export default DrawerHeader;

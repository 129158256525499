// dependencies
import React from "react";
import { useIntl } from "react-intl";

// components
import Layout, {
  ClassicLink,
  LabelLink,
  SearchLink,
  Logo,
  TextLogo,
} from "@gdf/resources/src/components/Layout";
import CookieConsent from "@gdf/resources/src/components/CookieConsent";
import Backdrop from "@gdf/resources/src/components/Backdrop";

// contexts
import { useConfiguration } from "@gdf/resources/src/contexts/configuration";
import { useRoute } from "@gdf/resources/src/contexts/route";
import { useRouters } from "@gdf/resources/src/contexts/routers";
import { useGoogleTracking } from "@gdf/resources/src/contexts/GoogleTracking";

// constants
import { CONTENT_ALIAS_VALUE_LABEL } from "@gdf/resources/src/constants/content";

// libraries
import EnhancedDate from "@gdf/resources/src/libraries/EnhancedDate";
import { formatPhone } from "@gdf/resources/src/libraries";
import { generateUri } from "@gdf/shared/src/libraries";

// helpers
import {
  layoutGetBottomBarLinkList,
  layoutGetHeaderMenuLinkList,
  layoutGetMainFooterColumnList,
  layoutGetMainNavigationLinkList,
  layoutGetSocialLinkList,
} from "@gdf/resources/src/helpers/layout";

// hooks
import useDisableScroll from "@gdf/resources/src/hocs/useDisableScroll";

type PropsType = {};

const LayoutContainer: React.FunctionComponent<PropsType> = (props) => {
  const { children } = props;

  const configuration = useConfiguration();

  const intl = useIntl();

  const { frontRouter, accountRouter } = useRouters();

  const route = useRoute();

  const { renderBanner, acceptCookies, refuseCookies } = useGoogleTracking();

  const currentPath = route.generateUri().getPath();

  useDisableScroll(renderBanner);

  return (
    <Backdrop
      isVisible={renderBanner}
      content={
        <CookieConsent
          onPressAccept={acceptCookies}
          onPressRefuse={refuseCookies}
        />
      }
    >
      <Layout
        headerMenuLinkList={layoutGetHeaderMenuLinkList({
          frontRouter,
          accountRouter,
          configuration,
          intl,
          currentPath,
        })}
        mainNavigationLinkList={layoutGetMainNavigationLinkList({
          frontRouter,
          configuration,
          intl,
          currentPath,
        })}
        mainFooterColumnList={layoutGetMainFooterColumnList({
          frontRouter,
          configuration,
          intl,
        })}
        bottomBarLinkList={layoutGetBottomBarLinkList({
          frontRouter,
          configuration,
          intl,
        })}
        socialLinkList={layoutGetSocialLinkList({ configuration })}
        copyright={`\u00a9\u00a0${EnhancedDate.fromDate(
          new Date(Date.now())
        ).getYear()} ${configuration.channel.title}`}
        socialLabel={intl.formatMessage(
          {
            id: "front.containers.layout.favoriteSocials",
          },
          { label: configuration.channel.title }
        )}
        phoneNumber={formatPhone(
          configuration.channel.informations.booking.phone
        )}
        openingTime={configuration.channel.informations.booking.schedule}
        highlightLinkList={[
          <SearchLink
            linkProps={{
              dataSet: {
                gtm: "header-page-room-list",
              },
            }}
            key="search"
            to={generateUri({
              router: frontRouter,
              name: "App.Action.RoomList",
              parameters: {
                lang: intl.locale,
              },
            })}
          >
            {intl.formatMessage({
              id: "front.containers.layout.search",
            })}
          </SearchLink>,

          ...(configuration.channel.website.contents.byAliases[
            CONTENT_ALIAS_VALUE_LABEL
          ]
            ? [
                <LabelLink
                  key="label"
                  to={generateUri({
                    router: frontRouter,
                    name: "App.Action.Content",
                    parameters: {
                      lang: intl.locale,
                      slug: configuration.channel.website.contents.byAliases[
                        CONTENT_ALIAS_VALUE_LABEL
                      ].slug,
                    },
                  })}
                >
                  {
                    configuration.channel.website.contents.byAliases[
                      CONTENT_ALIAS_VALUE_LABEL
                    ].title
                  }
                </LabelLink>,
              ]
            : []),
          <ClassicLink
            key="contact"
            linkProps={{
              dataSet: {
                gtm: "header-page-contact",
              },
            }}
            to={frontRouter
              .findByName("App.Action.Contact")
              .toFilled()
              .setParameter("lang", intl.locale)
              .generateUri()
              .toString()}
          >
            {intl.formatMessage({
              id: "front.containers.layout.contactUs",
            })}
          </ClassicLink>,
          <ClassicLink
            key="login"
            to={generateUri({
              router: accountRouter,
              name: "App.Action.Auth.Login",
              parameters: {
                lang: intl.locale,
              },
            })}
          >
            {intl.formatMessage({ id: "front.containers.layout.login" })}
          </ClassicLink>,
        ]}
        textLogo={
          <TextLogo
            to={generateUri({
              router: frontRouter,
              name: "App.Action.Home",
              parameters: {
                lang: intl.locale,
              },
            })}
            logoSource={{
              uri: configuration.channel.medias.logo,
            }}
          />
        }
        logo={
          <Logo
            logoSource={{ uri: configuration.channel.medias.avatar }}
            title={configuration.channel.website.title}
            description={configuration.channel.website.description}
          />
        }
      >
        {children}
      </Layout>
    </Backdrop>
  );
};

export default LayoutContainer;

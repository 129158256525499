// constants
import { USER_TOKEN_COOKIE_NAME } from "../../../constants/user";

import UserToken from "@gdf/resources/src/api/user/UserToken";

type GetThemeType = {
  cookies?: { [name: string]: string };
} & {
  rawUserToken?: { token: string };
};

type GetUserTokenReturnType = {
  userToken: UserToken;
};

/**
 * Récupère le token de l'utilisateur courant.
 */
const getUserToken = ({
  cookies,
  rawUserToken,
}: GetThemeType): GetUserTokenReturnType => {
  if (rawUserToken instanceof UserToken) {
    return { userToken: rawUserToken };
  } else if (undefined !== rawUserToken) {
    return {
      userToken: new UserToken({
        token: rawUserToken.token,
      }),
    };
  }

  const token = cookies ? cookies[USER_TOKEN_COOKIE_NAME] : undefined;

  if (undefined !== token) {
    return {
      userToken: new UserToken({ token }),
    };
  }

  return {
    userToken: new UserToken(),
  };
};

export default getUserToken;

// deps
import React from "react";
import PropTypes from "prop-types";
import { css, StyleSheet } from "aphrodite";

const styles = StyleSheet.create({
  children: {
    position: "relative",
  },
  background: {
    position: "absolute",
    top: 0,
    right: 0,
    bottom: 0,
    left: 0,
    backgroundColor: "rgba(0, 0, 0, .5)",
  },
});

function Backdrop(props) {
  const { content, contentMode, isVisible, children } = props;

  return (
    <div>
      {isVisible && "before" === contentMode && <div>{content}</div>}

      <div className={css(styles.children)}>
        {children}
        {isVisible && <div className={css(styles.background)} />}
      </div>

      {isVisible && "after" === contentMode && <div>{content}</div>}
    </div>
  );
}

Backdrop.propTypes = {
  isVisible: PropTypes.bool.isRequired,
  contentMode: PropTypes.oneOf(["before", "after"]),
  content: PropTypes.node.isRequired,
  children: PropTypes.node.isRequired,
};

Backdrop.defaultProps = {
  contentMode: "before",
};

export default Backdrop;

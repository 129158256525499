import { CONTENT_TYPE_CAMPAIGN } from "@gdf/resources/src/constants/content";

type ContentIsTypeCampaignParam0Type = {
  content:
    | ReturnType<
        typeof import("@gdf/resources/src/normalizers/content").contentParseData
      >
    | ReturnType<
        typeof import("@gdf/resources/src/normalizers/configuration").configurationParseData
      >["channel"]["website"]["contents"]["list"][0];
};

/**
 * Retourne vrai si le content est une campagne.
 */
const contentIsTypeCampaign = ({
  content,
}: ContentIsTypeCampaignParam0Type): boolean =>
  CONTENT_TYPE_CAMPAIGN === content.type;

export default contentIsTypeCampaign;

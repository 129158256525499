// libraries
import { generateUri } from "@gdf/shared/src/libraries";

/**
 * Génère les différents liens du fil d'Ariane pour la page des thématiques.
 */
const generateDealListBreacrumbItemList = ({ intl, route, router }) => {
  return [
    {
      label: intl.formatMessage({
        id: "app.constants.breadcrumb.name.App.Action.Home",
      }),
      to: generateUri({
        router,
        name: "App.Action.Home",
        parameters: {
          lang: route.getParameter("lang"),
        },
      }),
    },
    {
      label: intl.formatMessage({
        id: "app.constants.breadcrumb.name.App.Action.CityList",
      }),
      to: generateUri({
        router,
        name: "App.Action.CityList",
        parameters: {
          lang: route.getParameter("lang"),
        },
      }),
    },
  ];
};

export default generateDealListBreacrumbItemList;

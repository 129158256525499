import { CONTENT_TYPE_CATEGORY } from "@gdf/resources/src/constants/content";

type ContentIsTypeCategoryParam0Type = {
  content:
    | ReturnType<
        typeof import("@gdf/resources/src/normalizers/content").contentParseData
      >
    | ReturnType<
        typeof import("@gdf/resources/src/normalizers/configuration").configurationParseData
      >["channel"]["website"]["contents"]["list"][0];
};

/**
 * Retourne vrai si le content est une catégorie.
 */
const contentIsTypeCategory = ({
  content,
}: ContentIsTypeCategoryParam0Type): boolean =>
  CONTENT_TYPE_CATEGORY === content.type;

export default contentIsTypeCategory;

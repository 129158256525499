// dependencies
import React, { useContext } from "react";

// libraries
import { FilledRoute } from "@gdf/shared/src/libraries/Router";

const route = React.createContext<FilledRoute>(null);

type ProviderPropsType = {
  route: FilledRoute;
};

export const RouteProvider: React.FunctionComponent<ProviderPropsType> = (
  props
) => {
  const { route: value, ...otherProps } = props;

  const Provider = route.Provider;

  return <Provider {...otherProps} value={value} />;
};

export const RouteConsumer = route.Consumer;

export const useRoute = () => {
  return useContext(route);
};

export default route;

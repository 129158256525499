// helpers
import { contentGenerateUriRelayOnType } from "@gdf/resources/src/helpers/content";

// constants
import {
  CONTENT_TYPE_FREE,
  CONTENT_TYPE_PAGE,
  CONTENT_TYPE_CATEGORY,
  CONTENT_SECTION_VALUE_CITY,
  CONTENT_SECTION_VALUE_PROMO,
  CONTENT_SECTION_VALUE_THEME,
  CONTENT_SECTION_VALUE_FOOTER,
} from "@gdf/resources/src/constants/content";

// libraries
import { get } from "@gdf/shared/src/libraries";

type LayoutGetMainFooterColumnListParam0Type = {
  intl: import("react-intl").IntlShape;
  configuration: ReturnType<
    typeof import("@gdf/resources/src/normalizers/configuration").configurationParseData
  >;
  frontRouter: import("@gdf/shared/src/libraries/Router").Router;
};

const layoutGetMainFooterColumnList = ({
  intl,
  configuration,
  frontRouter,
}: LayoutGetMainFooterColumnListParam0Type) => {
  return [
    {
      title: intl.formatMessage({
        id: "front.containers.layout.usefulLinks",
      }),
      linkList: [
        ...get(
          configuration,
          [
            "channel",
            "website",
            "contents",
            "bySection",
            CONTENT_SECTION_VALUE_FOOTER,
          ],
          []
        )
          .filter((content) => {
            return (
              [CONTENT_TYPE_FREE, CONTENT_TYPE_PAGE].includes(content.type) &&
              !["discover", "city"].includes(content.alias)
            );
          })
          .map((content) => ({
            label: content.title,
            to: contentGenerateUriRelayOnType({ intl, content, frontRouter }),
          })),
        {
          label: intl.formatMessage({
            id: "front.containers.layout.contactUs",
          }),
          to: frontRouter
            .findByName("App.Action.Contact")
            .toFilled()
            .setParameter("lang", intl.locale)
            .generateUri()
            .toString(),
        },
      ],
    },
    {
      title: intl.formatMessage({ id: "front.containers.layout.rooms" }),
      linkList: [
        ...(get(
          configuration,
          [
            "channel",
            "website",
            "contents",
            "bySection",
            CONTENT_SECTION_VALUE_THEME,
          ],
          []
        ).length > 0
          ? [
              {
                label: intl.formatMessage({
                  id: "front.containers.layout.campaign",
                }),
                to: frontRouter
                  .findByName("App.Action.CampaignList")
                  .toFilled()
                  .setParameter("lang", intl.locale)
                  .generateUri()
                  .toString(),
              },
            ]
          : []),
        ...(get(
          configuration,
          [
            "channel",
            "website",
            "contents",
            "bySection",
            CONTENT_SECTION_VALUE_CITY,
          ],
          []
        ).length > 0
          ? [
              {
                label: intl.formatMessage({
                  id: "front.containers.layout.city",
                }),
                to: frontRouter
                  .findByName("App.Action.CityList")
                  .toFilled()
                  .setParameter("lang", intl.locale)
                  .generateUri()
                  .toString(),
              },
            ]
          : []),
        ...get(
          configuration,
          [
            "channel",
            "website",
            "contents",
            "bySection",
            CONTENT_SECTION_VALUE_FOOTER,
          ],
          []
        )
          .filter((content) => {
            return (
              [CONTENT_TYPE_FREE, CONTENT_TYPE_PAGE].includes(content.type) &&
              ["discover", "city"].includes(content.alias)
            );
          })
          .map((content) => ({
            label: content.title,
            to: contentGenerateUriRelayOnType({ intl, content, frontRouter }),
          })),
        ...(get(
          configuration,
          [
            "channel",
            "website",
            "contents",
            "bySection",
            CONTENT_SECTION_VALUE_PROMO,
          ],
          []
        ).length > 0
          ? [
              {
                label: intl.formatMessage({
                  id: "front.containers.layout.deal",
                }),
                to: frontRouter
                  .findByName("App.Action.DealList")
                  .toFilled()
                  .setParameter("lang", intl.locale)
                  .generateUri()
                  .toString(),
              },
            ]
          : []),
      ],
    },
    {
      title: intl.formatMessage({
        id: "front.containers.layout.roomTypes",
      }),
      linkList: get(
        configuration,
        [
          "channel",
          "website",
          "contents",
          "bySection",
          CONTENT_SECTION_VALUE_FOOTER,
        ],
        []
      )
        .filter((content) => CONTENT_TYPE_CATEGORY === content.type)
        .map((content) => {
          return {
            label: content.title,
            to: contentGenerateUriRelayOnType({ intl, content, frontRouter }),
          };
        }),
    },
  ];
};

export default layoutGetMainFooterColumnList;

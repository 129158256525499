// libraries
import encodeFormData from "../encodeFormData";

// constants
import {
  METHOD_VALUE_GET,
  METHOD_VALUE_POST,
  METHOD_VALUE_DELETE,
  METHOD_VALUE_PUT,
  METHOD_VALUE_PATCH,
  METHOD_VALUE_CONNECT,
  METHOD_VALUE_HEAD,
  METHOD_VALUE_OPTIONS,
  METHOD_VALUE_TRACE,
} from "../../../constants/methods";
import { RESPONSE_STATUS_VALUE_UNAUTHORIZED } from "../../../constants/response";

type RequestType = {
  url: Request | string;
  method?:
    | typeof METHOD_VALUE_GET
    | typeof METHOD_VALUE_POST
    | typeof METHOD_VALUE_DELETE
    | typeof METHOD_VALUE_PUT
    | typeof METHOD_VALUE_PATCH
    | typeof METHOD_VALUE_CONNECT
    | typeof METHOD_VALUE_HEAD
    | typeof METHOD_VALUE_OPTIONS
    | typeof METHOD_VALUE_TRACE;
  mode?: "navigate" | "same-origin" | "no-cors" | "cors";
  body?:
    | Blob
    | BufferSource
    | FormData
    | URLSearchParams
    | ReadableStream<Uint8Array>
    | string
    | { [name: string]: any };
  headers?: Headers | string[][] | Record<string, string>;
  type?: "json" | "formData";
  context?: { token?: string; channelId?: string; onUnauthorized?: () => void };
};

const request = ({
  url,
  method,
  mode = "cors",
  body,
  headers = {},
  type,
  context,
}: RequestType) => {
  const newHeaders = new Headers(headers);
  let newBody: any = body;

  if (method === METHOD_VALUE_GET || method === METHOD_VALUE_HEAD) {
    newBody = undefined;
  } else {
    switch (type) {
      case "json": {
        if (!newHeaders.has("Content-Type")) {
          newHeaders.append("Content-Type", "application/json");
        }

        if ("object" === typeof body && null !== body) {
          newBody = JSON.stringify(body);
        }

        break;
      }

      case "formData": {
        if ("object" === typeof body && null !== body) {
          newBody = encodeFormData(body);
        }

        break;
      }
    }
  }

  if (undefined !== context) {
    const { token, channelId } = context;

    if (null !== channelId) {
      newHeaders.append("Channel", channelId);
    }

    if (token) {
      newHeaders.append("Authorization", `Bearer ${token}`);
    }
  }

  return fetch(url, {
    method,
    mode,
    body: newBody,
    headers: newHeaders,
  }).then((response) => {
    if ("application/json" === response.headers.get("content-type")) {
      return Promise.resolve(response.json()).then((jsonBody) => {
        if (
          undefined !== context &&
          RESPONSE_STATUS_VALUE_UNAUTHORIZED === response.status
        ) {
          // Si le contexte est défini et que le code de retour est 401,
          //   on appelle un callback.

          const { onUnauthorized } = context;

          if ("function" === typeof onUnauthorized) {
            onUnauthorized();
          }
        }

        return response.ok
          ? Promise.resolve({ body: jsonBody, status: response.status })
          : Promise.reject({ body: jsonBody, status: response.status });
      });
    } else {
      return Promise.resolve(response.text()).then((textBody) => {
        return response.ok
          ? Promise.resolve({ body: textBody, status: response.status })
          : Promise.reject({ body: textBody, status: response.status });
      });
    }
  });
};

export default request;

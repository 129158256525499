// dependencies
import React, { useContext, useState, useEffect } from "react";

const userToken = React.createContext<{
  userToken: ReturnType<
    typeof import("../api/user/getUserToken").default
  >["userToken"];
}>(null);

const Provider = userToken.Provider;

type ProviderPropsType = {
  userToken: ReturnType<
    typeof import("../api/user/getUserToken").default
  >["userToken"];
};

export const UserTokenProvider: React.FunctionComponent<ProviderPropsType> = (
  props
) => {
  const { userToken: value, ...otherProps } = props;

  return <Provider {...otherProps} value={{ userToken: value }} />;
};

export const UserTokenConsumer = userToken.Consumer;

export const useUserToken = () => {
  const context = useContext(userToken);
  const [, forceUpdate] = useState(null);

  if (!context) {
    throw new Error("Missing provider for <UserToken> in _app.tsx");
  }

  useEffect(() => {
    context.userToken.on("update", forceUpdate);

    return () => {
      context.userToken.off("update", forceUpdate);
    };
  }, [context.userToken]);

  return context;
};

export default userToken;

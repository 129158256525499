import hasOwnProperty from "../utils/hasOwnProperty";
import Event from "./event";

class EventDispatcher {
  // tslint:disable-next-line: ban-types
  private readonly handlers: { [name: string]: Function[] } = {};

  public constructor() {
    this.onceHandler = this.onceHandler.bind(this);
  }

  public on(name, handler): void {
    if (!hasOwnProperty(this.handlers, name)) {
      this.handlers[name] = [];
    }

    this.handlers[name].push(handler);
  }

  public once(name, handler): void {
    this.on(name, this.onceHandler(name, handler));
  }

  public off(name, handler, all = false): void {
    if (hasOwnProperty(this.handlers, name)) {
      if (all) {
        this.handlers[name] = [];
      } else {
        this.handlers[name].splice(this.handlers[name].indexOf(handler), 1);
      }
    }
  }

  public dispatch(event: Event): void {
    const name = event.getName();

    if (hasOwnProperty(this.handlers, name)) {
      const handlers = [...this.handlers[name]];

      for (
        let i = 0;
        i < handlers.length && !event.isPropagationStopped();
        i += 1
      ) {
        const handler = handlers[i];
        handler.call(null, event);
      }
    }
  }

  public getNumberOfListeners() {
    return Object.keys(this.handlers).length;
  }

  private onceHandler(name, handler) {
    const call = (...args) => {
      this.off(name, call);

      handler(...args);
    };

    return call;
  }
}

export default EventDispatcher;

import {
  SECONDS_TO_MINUTES_BASE,
  MILLISECONDS_TO_SECOND_BASE,
} from "@gdf/shared/src/constants/time";

export const USER_TOKEN_COOKIE_NAME =
  process.env.NEXT_PUBLIC_USER_ACCESS_TOKEN_COOKIE_NAME ?? "access_token";

/**
 * User genres
 */

export const USER_GENDER_VALUE_MR = "Mr";
export const USER_GENDER_VALUE_MRS = "Mrs";
export const USER_GENDER_VALUE_MMS = "Mms";
export const USER_GENDER_VALUE_UN = "Un";

export const GENDERS = {
  [USER_GENDER_VALUE_MR]: {
    key: USER_GENDER_VALUE_MR,
    value: USER_GENDER_VALUE_MR,
    hidden: false,
  },
  [USER_GENDER_VALUE_MRS]: {
    key: USER_GENDER_VALUE_MRS,
    value: USER_GENDER_VALUE_MRS,
    hidden: false,
  },
  [USER_GENDER_VALUE_MMS]: {
    key: USER_GENDER_VALUE_MMS,
    value: USER_GENDER_VALUE_MMS,
    hidden: false,
  },
  [USER_GENDER_VALUE_UN]: {
    key: USER_GENDER_VALUE_UN,
    value: USER_GENDER_VALUE_UN,
    hidden: true,
  },
};

export const GENDER_LIST = Object.values(GENDERS);

/**
 * Login context
 */

export const USER_LOGIN_CONTEXT_WEBSITE = "website";

export const USER_RENEW_DELAY =
  10 * SECONDS_TO_MINUTES_BASE * MILLISECONDS_TO_SECOND_BASE;

// constants
import {
  accountRouter,
  bookingRouter,
  frontRouter,
} from "@gdf/shared/src/constants/router";

// libraries
import Uri from "@gdf/shared/src/libraries/Uri";

/**
 * Retourne l’ensemble des routers lié au contexte du site.
 */
function appGetRouters({ domain, packageName }) {
  // Booking
  let bookingHost = undefined;
  let bookingPort = undefined;
  let bookingScheme = undefined;
  if ("booking" !== packageName) {
    bookingHost = `${process.env.NEXT_PUBLIC_BOOKING_SUBDOMAIN}.${domain}`;
    bookingPort = undefined;
    bookingScheme = "https";
  }

  const localBookingRouter = bookingRouter.withUri(
    new Uri(bookingScheme, undefined, bookingHost, bookingPort)
  );

  // Account
  let accountHost = undefined;
  let accountPort = undefined;
  let accountScheme = undefined;
  if ("account" !== packageName) {
    accountHost = `${process.env.NEXT_PUBLIC_ACCOUNT_SUBDOMAIN}.${domain}`;
    accountPort = undefined;
    accountScheme = "https";
  }

  const localAccountRouter = accountRouter.withUri(
    new Uri(accountScheme, undefined, accountHost, accountPort)
  );

  // Front
  let frontHost = undefined;
  let frontPort = undefined;
  let frontScheme = undefined;
  if ("front" !== packageName) {
    frontHost = `${process.env.NEXT_PUBLIC_FRONT_SUBDOMAIN}.${domain}`;
    frontPort = undefined;
    frontScheme = "https";
  }

  const localFrontRouter = frontRouter.withUri(
    new Uri(frontScheme, undefined, frontHost, frontPort)
  );

  let mainRouter;
  switch (packageName) {
    case "account": {
      mainRouter = localAccountRouter;
      break;
    }

    case "booking":
      mainRouter = localBookingRouter;
      break;

    case "front":
    default: {
      mainRouter = localFrontRouter;
      break;
    }
  }

  return {
    bookingRouter: localBookingRouter,
    accountRouter: localAccountRouter,
    frontRouter: localFrontRouter,
    mainRouter,
  };
}

export default appGetRouters;

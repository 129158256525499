// dependencies
import React from "react";
import { StyleSheet } from "react-native";

// libraries
import { emToPx } from "../../libraries";

// components
import { IconSearchO } from "@gdf/svg-icon-library";
import Text from "../Text";
import Link from "../Link";
import IconText from "../IconText";

// hocs
import useHover from "../../hocs/useHover";

type IProps = {
  to: string;
  linkProps?: any;
};

const styles = StyleSheet.create({
  link: {
    display: "flex",
    alignItems: "center",
  },
  linkText: {
    fontWeight: "600",
    fontSize: emToPx(0.875),
  },
  iconWrapper: {
    fontSize: emToPx(1),
  },
  linkText__hovered: {
    textDecorationLine: "underline",
  },
});

const SearchLink: React.FunctionComponent<IProps> = (props) => {
  const { to, linkProps, children } = props;
  const { hovered, onMouseEnter, onMouseLeave } = useHover();

  return (
    <Link {...linkProps} style={styles.link} to={to}>
      <IconText Icon={IconSearchO} iconWrapperStyle={styles.iconWrapper}>
        <Text
          onMouseEnter={onMouseEnter}
          onMouseLeave={onMouseLeave}
          style={[styles.linkText, hovered && styles.linkText__hovered]}
        >
          {children}
        </Text>
      </IconText>
    </Link>
  );
};

export default SearchLink;

// dependencies
import React, { useMemo } from "react";
import { StyleProp, TextStyle, TextProps } from "react-native";
import NextLink from "next/link";

// components
import Text from "../../Text";

// libraries
import Uri from "@gdf/shared/src/libraries/Uri";
import { FilledRoute } from "@gdf/shared/src/libraries/Router";
import { getRoute } from "@gdf/resources/src/libraries";

// contexts
import { useRouters } from "@gdf/resources/src/contexts/routers";

// hocs
import useHover from "@gdf/resources/src/hocs/useHover";

type PropsType = {
  target?: string;
  to: string | FilledRoute | Uri;
  hoverStyle?: StyleProp<TextStyle>;
  external?: boolean;
} & TextProps;

const Link = React.forwardRef<Text, React.PropsWithChildren<PropsType>>(
  function Link(props, ref) {
    const {
      to,
      style,
      external = false,
      hoverStyle,
      target,
      ...otherProps
    } = props;

    const { mainRouter } = useRouters();

    const { hovered, onMouseEnter, onMouseLeave } = useHover();

    const { nextLink, href } = getRoute({
      to,
      external,
      router: mainRouter,
    });

    const hrefAttrs = useMemo(
      function () {
        return { target };
      },
      [target]
    );

    if (nextLink) {
      return (
        <NextLink {...otherProps} href={href} passHref={true} ref={ref}>
          <Text
            {...otherProps}
            hrefAttrs={hrefAttrs}
            ref={ref as any}
            style={[style, hovered && hoverStyle]}
            accessibilityRole="link"
            onMouseEnter={onMouseEnter}
            onMouseLeave={onMouseLeave}
          />
        </NextLink>
      );
    } else {
      return (
        <Text
          {...otherProps}
          ref={ref as any}
          hrefAttrs={hrefAttrs}
          style={[style, hovered && hoverStyle]}
          accessibilityRole="link"
          href={to}
          onMouseEnter={onMouseEnter}
          onMouseLeave={onMouseLeave}
        />
      );
    }
  }
);

export default Link;

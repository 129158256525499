type ContentGetSlugFromCategoryAlias = {
  categoryAlias: string;
  contentList: ReturnType<
    typeof import("@gdf/resources/src/normalizers/configuration").configurationParseData
  >["channel"]["website"]["contents"]["list"];
};

/**
 * Retourne le slug en fonction de l'alias de la catégorie.
 */
const contentGetSlugFromCategoryAlias = ({
  categoryAlias,
  contentList,
}: ContentGetSlugFromCategoryAlias): string => {
  return [...contentList].reduce((slug, content, contentIndex, contentList) => {
    if (categoryAlias === content.alias) {
      contentList.splice(1);

      return content.slug;
    }

    return slug;
  }, categoryAlias);
};

export default contentGetSlugFromCategoryAlias;

// dependencies
import React, { useState, useCallback, useMemo } from "react";
import { useIntl } from "react-intl";
import { StyleSheet, css } from "aphrodite";

// components
import { IconMenuO, IconCrossO } from "@gdf/svg-icon-library";
import Text from "@gdf/resources/src/components/Text2";
import Portal from "@gdf/resources/src/components/Portal";
import Drawer from "@gdf/resources/src/components/Drawer";
// import { LinkMenu } from "@gdf/resources/src/components/Link";
import Link from "./Link";

// constants
import theming from "@gdf/resources/src/constants/theming";

// libraries
import { capitalize } from "@gdf/shared/src/libraries";

const { useTheme } = theming;

export type LinkType = {
  label: string;
  to: string;
  isActivated: boolean;
};

type PropsType = {
  selector: string;
  linkList?: LinkType[];
};

const styles = StyleSheet.create({
  menu: {
    cursor: "pointer",
  },
  linkList: {
    display: "flex",
    flexDirection: "column",
    minWidth: 250,
  },
  icon: {
    fontSize: "1.125rem",
  },
  label: {
    display: "flex",
    alignItems: "center",
    fontSize: "0.875rem",
  },
  header: {
    display: "flex",
    padding: "0.5rem",
    height: "3.125rem",
    overflow: "hidden",
    width: "100%",
    flexDirection: "row",
    justifyContent: "space-between",
    alignItems: "center",
  },
  headerText: {
    color: "#ffffff",
  },
  close: {
    color: "#ffffff",
    cursor: "pointer",
    position: "relative",
    ":before": {
      content: '""',
      position: "absolute",
      top: "-0.625rem",
      right: "-0.625rem",
      bottom: "-0.625rem",
      left: "-0.625rem",
    },
  },
});

const MenuDropdown: React.FunctionComponent<PropsType> = (props) => {
  const { selector, linkList } = props;

  const intl = useIntl();

  const [opened, setOpened] = useState(false);

  const theme = useTheme();

  const label = intl.formatMessage({ id: "app.components.menuDrawer.menu" });

  const dynamicStyles = useMemo(
    () =>
      StyleSheet.create({
        header: {
          backgroundColor: theme.PRIMARY_COLOR,
        },
      }),
    [theme]
  );

  const handleShouldClose = useCallback(() => {
    setOpened(false);
  }, []);

  const handlePressOpen = useCallback(() => setOpened(true), []);

  return (
    <>
      <Portal selector={selector}>
        <Drawer isOpened={opened} onShouldClose={handleShouldClose}>
          <nav
            className={css(styles.linkList)}
            aria-label={intl.formatMessage({
              id: "app.components.menuDrawer.label",
            })}
          >
            <div>
              <div className={css(styles.header, dynamicStyles.header)}>
                <Text aStyle={styles.headerText}>
                  {capitalize(intl.formatMessage({ id: "app.global.welcome" }))}
                </Text>

                <Text
                  role="button"
                  aria-label={intl.formatMessage({
                    id: "app.components.menuDrawer.icon.close",
                  })}
                  aStyle={styles.close}
                  onClick={handleShouldClose}
                >
                  <IconCrossO />
                </Text>
              </div>
            </div>

            {linkList.map((link, index) => {
              const { label, to, isActivated } = link;

              return (
                <Link
                  key={index}
                  label={label}
                  isActivated={isActivated}
                  to={to}
                  onPress={handleShouldClose}
                />
              );
            })}
          </nav>
        </Drawer>
      </Portal>

      <div onClick={handlePressOpen}>
        <Text
          aStyle={styles.menu}
          role="button"
          aria-label={label}
          aria-expanded={opened}
          aria-pressed={opened}
          aria-haspopup={true}
        >
          <Text aStyle={styles.label}>
            <IconMenuO />
            &nbsp;{label}
          </Text>
        </Text>
      </div>
    </>
  );
};

MenuDropdown.defaultProps = {
  linkList: [],
};

export default MenuDropdown;

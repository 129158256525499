// constants
import getCache from "../../constants/cache";

// normalizers
import { configurationParseResponse } from "../../normalizers/configuration";

// constants
import { WINDOW_CONFIGURATION_KEY } from "../../constants/configuration";
import { METHOD_VALUE_GET } from "@gdf/shared/src/constants/methods";
import { apiRouter } from "@gdf/shared/src/constants/router";

// libraries
import { request, generateUri } from "@gdf/shared/src/libraries";

type GetConfigurationReturnType = {
  configuration: ReturnType<
    typeof import("../../normalizers/configuration").configurationParseResponse
  >;
};

/**
 * Récupère la configuration de l'application.
 * Étapes côté serveur:
 * - si on possède des données dans le cache court-terme de Redis alors
 *     - on retourne ces données.
 * - sinon
 *     - on tente un appel API pour récupérer les données à jour
 *        - si l'appel API a fonctionné alors
 *          - on stocke les données de le cache long et court-terme de Redis
 *          - on renvoie les données à l'utilisateur
 *        - sinon
 *          - on tente d'appeler le cache long terme de Redis
 *            - si l'appel Redis a fonctionné alors
 *              - on retourne les données stockée dans le cache long-terme
 *            - sinon
 *              - on renvoie une erreur critique
 */
async function getConfiguration({
  domainOrChannelId,
}): Promise<GetConfigurationReturnType> {
  if (process.browser) {
    // Tant que l'on navigue sur les pages côté client, on utilise
    //   la configuration stockée dans l'objet `window`.

    const configuration =
      window[`${WINDOW_CONFIGURATION_KEY}.${domainOrChannelId}`];

    if (undefined === configuration) {
      // On est passé d'un channel à un autre, on re-charge la page.
      console.info(
        "Switch from one channel to another, reload the page to get the updated configuration."
      );
      window.location.reload();
    }

    return { configuration };
  } else {
    const GET_CONFIG_CACHE_KEY = `monolith:cache:channels:${domainOrChannelId}:configuration:fr`;

    const cache = await getCache();

    const isCacheEnabled = process.env.ENABLE_CACHE_EXPIRATION === "ON";

    if (isCacheEnabled && (await cache.has(GET_CONFIG_CACHE_KEY))) {
      // On a du cache court-terme disponible, on l'utilise.

      const cachedResponse = await cache.get(GET_CONFIG_CACHE_KEY);

      return {
        configuration: configurationParseResponse({
          data: { data: cachedResponse },
        }),
      };
    } else {
      // On n'a pas de cache court-terme, on fait une requête.

      return await request({
        url: generateUri({
          router: apiRouter,
          name: "Api.Channels.Action.Configuration",
          query: { domain: domainOrChannelId },
        }),
        method: METHOD_VALUE_GET,
      }).then(({ body: configuration }) => {
        return {
          configuration: configurationParseResponse({
            data: configuration,
          }),
        };
      });
    }
  }
}

export default getConfiguration;

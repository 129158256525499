// dependencies
import React, { useEffect } from "react";
import { StyleSheet, css } from "aphrodite";

// components
import UIContainer from "@gdf/resources/src/components/UIContainer2";
import { Row, Col } from "@gdf/resources/src/components/Grid2";

const styles = StyleSheet.create({
  globalError: {
    flex: 1,
    display: "flex",
    alignItems: "center",
  },
  content: {
    display: "flex",
    justifyContent: "center",
  },
  imageWrapper: {
    maxWidth: "50em",
    width: "100%",
  },
  errorMessage: {
    fontFamily: "sans-serif",
    textAlign: "center",
    fontSize: 24,
  },
});

type PropsType = {
  error?;
};

const GlobalError: React.FunctionComponent<PropsType> = (props) => {
  const { error } = props;

  useEffect(() => {
    console.error(error);
  }, [error]);

  return (
    <>
      <div className={css(styles.globalError)}>
        <UIContainer fullWidth={true}>
          <div className={css(styles.content)}>
            <Row type="column" gutter={0.5}>
              <Col>
                <div className={css(styles.imageWrapper)}>
                  <img
                    style={{ width: "100%" }}
                    alt="Une erreur interne est survenue"
                    src={"/images/splashes/internal-error.png"}
                  />
                </div>
              </Col>

              <Col>
                <div className={css(styles.errorMessage)}>
                  Le site est momentanément indisponible.
                </div>
              </Col>
            </Row>
          </div>
        </UIContainer>
      </div>
    </>
  );
};

export default GlobalError;

// dependencies
import cookie from "cookie";

/**
 *
 * @param {object} param0
 * @param {string} param0.name
 * @param {boolean} [param0.secure=false]
 * @param {string} [param0.domain]
 * @param {boolean} [param0.httpOnly=false]
 * @param {string} [param0.path]
 * @param {"lax" | "none" | "strict"} [param0.sameSite]
 */
const deleteCookie = ({
  name,
  path = "/",
  domain,
  httpOnly,
  sameSite,
  secure = false,
}) => {
  if (process.browser) {
    document.cookie = cookie.serialize(name, "[deleted]", {
      expires: new Date(1970, 0, 1, 0, 0, 1),
      path,
      secure,
      domain,
      httpOnly,
      sameSite,
    });
  } else {
    console.error("Be careful, cannot delete cookie server-side.");
  }
};

export default deleteCookie;

// dependencies
import React, { Fragment } from "react";
import { View } from "react-native";

// components
import Header from "@gdf/resources/src/components/Header";
import MenuDropdown from "@gdf/resources/src/components/MenuDropdown";
import Separator from "@gdf/resources/src/components/Separator";
import MainNavigation from "@gdf/resources/src/components/MainNavigation";
import MainFooter from "@gdf/resources/src/components/MainFooter";
import BottomBar from "@gdf/resources/src/components/BottomBar";
import SocialBar from "@gdf/resources/src/components/SocialBar";
import { SocialList } from "@gdf/resources/src/components/Social";
import Stack from "@gdf/resources/src/components/Stack";
import { List, ListItem } from "@gdf/resources/src/components/List";

// libraries
import { emToPx } from "@gdf/resources/src/libraries";

type PropsType = {
  headerMenuLinkList: any[];
  mainNavigationLinkList: any[];
  mainFooterColumnList: any[];
  bottomBarLinkList: any[];
  socialLinkList: {
    social;
    to: string;
  }[];
  copyright: string;
  socialLabel: string;
  phoneNumber: string;
  openingTime: string;
  logo: React.ReactNode;
  textLogo: React.ReactNode;
  highlightLinkList: React.ReactNode[];
};

const Layout: React.FunctionComponent<PropsType> = (props) => {
  const {
    headerMenuLinkList,
    mainNavigationLinkList,
    mainFooterColumnList,
    bottomBarLinkList,
    socialLinkList,
    copyright,
    socialLabel,
    phoneNumber,
    openingTime,
    logo,
    textLogo,
    highlightLinkList,
    children,
  } = props;

  return (
    <Fragment>
      <Header
        logo={textLogo}
        highlight={
          highlightLinkList.length > 0 ? (
            <List gutter={2} inline={true}>
              {highlightLinkList.map((highlightLink, index) => (
                <ListItem key={index}>{highlightLink}</ListItem>
              ))}
            </List>
          ) : null
        }
        dropdown={
          <MenuDropdown selector="body" linkList={headerMenuLinkList} />
        }
      />

      <MainNavigation linkList={mainNavigationLinkList} />

      <View style={{ flex: 1 }}>{children}</View>

      <Stack marginTop={1} />

      <Separator color="#757575" style={{ borderBottomWidth: emToPx(0.125) }} />

      <Stack marginTop={1} />

      <MainFooter
        columnList={mainFooterColumnList}
        logo={logo}
        contactNumber={phoneNumber}
        openingTime={openingTime}
      />

      <Stack marginTop={1} />

      {socialLinkList.length > 0 && (
        <SocialBar
          renderSocialList={() => {
            return <SocialList socialList={socialLinkList} />;
          }}
          label={socialLabel}
        />
      )}

      <BottomBar linkList={bottomBarLinkList} label={copyright} />
    </Fragment>
  );
};

export default Layout;

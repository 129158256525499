import { IEvent } from "@isomorpher/event-interop";

class Event implements IEvent {
  /**
   * The name of the event.
   */
  protected readonly name: string;

  /**
   * Is the propagation of the event is actived.
   */
  protected propagationStopped = false;

  public constructor(name: string) {
    this.name = name;
  }

  public getName(): string {
    return this.name;
  }

  public stopPropagation() {
    this.propagationStopped = true;
  }

  public isPropagationStopped() {
    return this.propagationStopped;
  }
}

export default Event;
